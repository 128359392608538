import React from 'react';
import {graphql} from 'gatsby';
import Page from '../components/Page';

const Feed = ({data}) => {
  return (
    <Page
      title={data.feed.title}
      description={data.feed.description}
      path={data.feed.path}
      feedItems={data.feed.childrenFeedItem}
      searchPages={data.localSearchPages}
      breadcrumb="/feeds"
    />
  );
};

export default Feed;

export const FEED_ITEM_FRAGMENT = graphql`
  fragment FeedItemFragmentFeed on FeedItem {
    id
    pubDate
    title
    link
    author
    content
    ttr
    parent {
      ... on Feed {
        title
        path
        twitter
      }
    }
  }
`;

export const query = graphql`
  query FeedQuery($id: String) {
    feed(id: {eq: $id}) {
      id
      title
      path
      description
      childrenFeedItem {
        ...FeedItemFragmentFeed
      }
    }
  }
`;
